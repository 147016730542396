<template>
  <v-form ref="form" @submit.prevent="search">
    <h2>Pesquisar Movimentação dos Visitantes e Acompanhantes</h2>
    <v-container>
      <v-row>
        <AlertError
          class="mb-5"
          v-model="displayError"
          :resource="displayError"
        />
      </v-row>
      <v-row>
        <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="sticker_number"
            label="Número Etiqueta"
            required
            v-mask="'######################'"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="name" label="Nome" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="hospital_patient_name"
            label="Nome Paciente"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            v-model="functionalUnity"
            label="Unidade Funcional"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6" md="3">
          <v-text-field v-model="hospital_bed" label="Leito" required>
          </v-text-field>
        </v-col>

        <v-col cols="6" md="3">
          <v-text-field
            type="date"
            v-model="initialDate"
            label="Data Inicio"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6" md="3">
          <v-text-field
            type="date"
            v-model="finalDate"
            label="Data Final"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group v-model="historicalMovementType" row>
            <v-radio label="Entrada" value="entrada"></v-radio>
            <v-radio label="Saída" value="saida"></v-radio>
            <v-radio label="Entrada e Saída" value="entrada_saida"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="float-end primary" type="submit" dark color="success">
            <v-icon size="22" class="icon">mdi-magnify-plus-outline</v-icon>
            <span>Pesquisar</span>
          </v-btn>
          <v-btn
            class="float-end primary mr-2"
            @click="gerarRelatorio()"
            dark
            color="secondary"
          >
            <v-icon size="22" class="icon">mdi-magnify-plus-outline</v-icon>
            <span>Gerar Relatório</span>
          </v-btn>
          <v-btn
            @click="btlimpar"
            class="float-end primary"
            style="margin-right: 10px"
          >
            <v-icon size="22" class="icon">mdi-broom</v-icon>
            <span>Limpar</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="person"
          :pageCount="numberOfPages"
          :server-items-length="totalPerson"
          :page="page"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :items-per-page="10"
          :footer-props="{
            'items-per-page-text': 'Visitantes e Acompanhantes por Página',
            'items-per-page-options': [10, 25, 50, 100, -1],
          }"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.view="{ item }">
            <v-icon
              title="Sem saída"
              small
              class="mr-2"
              @click="viewM(item)"
              v-if="!item.departure_date"
            >
              mdi-eye
            </v-icon>
            <v-icon
              title="Saída confirmada"
              small
              @click="viewM(item)"
              color="red"
              v-if="item.departure_date"
            >
              mdi-eye-off
            </v-icon>
          </template>
          <template v-slot:[`item.pdf`]="{ item }">
            <v-icon
              title="PDF"
              color="red"
              @click="pdfMov(item)"
              :disabled="item.departure_date ? false : true"
            >
              mdi-file-pdf-box
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
      <!-- Transformar em componente -->
      <div v-show="showTheCode" id="print-sticker">
        <div style="text-align: center">
          <h2>Relatório de Movimentação</h2>
          <h4>{{ hospital_name }}</h4>
        </div>
        <table>
          <thead>
            <tr>
              <th style="border-bottom: 1px solid #000">
                Nome Visitante/Acompanhante
              </th>
              <th style="border-bottom: 1px solid #000">Nome Social</th>
              <th style="border-bottom: 1px solid #000">Tipo de Documento</th>
              <th style="border-bottom: 1px solid #000">Documento</th>
              <th style="border-bottom: 1px solid #000">Paciente</th>
              <th style="border-bottom: 1px solid #000">Leito</th>
              <th style="border-bottom: 1px solid #000">Unidade Funcional</th>
              <th style="border-bottom: 1px solid #000">Número da Etiqueta</th>
              <th style="border-bottom: 1px solid #000">
                Data/Hora da Entrada
              </th>
              <th style="border-bottom: 1px solid #000">Data/Hora de Saída</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in person" :key="item.id">
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.person[0].name_person }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.person[0].social_name }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.person[0].documents.name_type_document }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.person[0].documents.document_number }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.hospitalPatient[0].hospital_patient_name }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.hospitalPatient[0].hospital_bed }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.hospitalPatient[0].functional_unity }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.person[0].sticker.sticker_number }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.created_at }}
              </td>
              <td
                style="
                  border-bottom: 1px solid #000;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                "
              >
                {{ item.departure_date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import AlertError from "../alerts/Error-component.vue";
import fileDownload from "js-file-download";
//import VisualizarMovi from './VisualizarMovi-comp.vue';//
export default {
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Visitante/Acompanhante",
          align: "start",
          sortable: false,
          value: "person[0].name_person",
        },
        {
          text: "Tipo do Documento",
          value: "person[0].documents.name_type_document",
        },
        { text: "Documento", value: "person[0].documents.document_number" },

        { text: "Paciente", value: "hospitalPatient[0].hospital_patient_name" },

        { text: "Leito", value: "hospitalPatient[0].hospital_bed" },
        {
          text: "Unidade Funcional",
          value: "hospitalPatient[0].functional_unity",
        },
        { text: "Nº da Etiqueta", value: "person[0].sticker.sticker_number" },
        {
          text: "Data/Hora da Entrada",
          value: "created_at",
        },
        {
          text: "Data/Hora da Saída",
          value: "departure_date",
        },
        { text: "Visualizar", value: "view" },
        { text: "PDF", value: "pdf" },
      ],
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },

      page: 1,
      totalPerson: 0,
      numberOfPages: 0,
      dialogEdit: false,
      hospital_name: "",
      sticker_number: "",
      options: {},
      sortBy: "name",
      name: "",
      hospital_patient_name: "",
      functionalUnity: "",
      place: "",
      historicalMovementType: "entrada",
      hospital_bed: "",
      initialDate: "",
      finalDate: "",
      radioGroup: "",
      movimentacao: "",
      person: [],
    };
  },
  components: {
    AlertError,
    AlertSuccess,
  },
  watch: {
    sortBy: {
      handler() {},
    },
    options: {
      handler(e) {
        this.search(e);
      },
    },
    deep: true,
  },
  mounted() {
    this.search();
    this.getUnityHospitalUser(this.$store.state.authUser.id_unity_user);
  },
  methods: {
    getUnityHospitalUser(id) {
      axios
        .get(`/unity/${id}`)
        .then((response) => {
          this.hospital_name = response.data.unity_name;
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Ocorreu um erro!",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    getAllname() {
      axios.get(`/person/`).then((response) => {
        this.typePersonList = response.data;
      });
    },
    btlimpar() {
      this.sticker_number = "";
      this.name = "";
      this.hospital_patient_name = "";
      this.unity = "";
      this.place = "";
      this.initialDate = "";
      this.finalDate = "";
      this.movimentacao = "";
      this.functionalUnity = "";
      this.hospital_bed = "";
      this.historicalMovementType = "";
    },
    gerarRelatorio() {
      this.loading = true;
      const dados = new Object();
      dados.name = this.name;
      dados.hospital_patient_name = this.hospital_patient_name;
      dados.sticker_number = this.sticker_number;
      dados.functional_unity = this.functionalUnity;
      dados.hospital_bed = this.hospital_bed;
      dados.permanence = this.finalDate;
      dados.initialDate = this.initialDate;
      dados.historicalMovementType = "entrada_saida";
      dados.excel = true;
      axios
        .post(`/person/search-person`, dados, {
          responseType: "blob",
        })
        .then((e) => {
          fileDownload(e.data, "relatorio.xlsx");
          this.loading = false;
        });
    },
    search(e) {
      this.person = [];
      this.loading = true;
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.name = this.name;
        dados.hospital_patient_name = this.hospital_patient_name;
        dados.sticker_number = this.sticker_number;
        dados.functional_unity = this.functionalUnity;
        dados.hospital_bed = this.hospital_bed;
        dados.permanence = this.finalDate;
        dados.initialDate = this.initialDate;
        dados.historicalMovementType = this.historicalMovementType;
        dados.per_page = this.options.itemsPerPage;
        dados.page = e.page;
        axios
          .post(`/person/search-person`, dados)
          .then((response) => {
            this.person = response.data.data;
            this.totalPerson = response.data.meta.total;
            this.numberOfPages = response.data.meta.last_page;
            this.page = response.data.meta.current_page;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    viewM(item) {
      this.dialogEdit = true;
      this.$router.push({ path: "/VisualizarMovi", query: { item } });
    },
    pdfMov(item) {
      axios
        .post("declaration-pdf", item, { responseType: "blob" })
        .then((response) => {
          fileDownload(response.data, "declaracao.pdf");
        });
    },
  },
};
</script>
<style>
.v-data-table {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
</style>
